<template>
  <div class="cedantRegistrationStep4">
    <h3 class="gavc-h3">Validation et création de votre compte</h3>
    <div
      class="gavc-field"
      v-bind:class="{ 'is-error': cedantUserUsername && $v.cedantUserUsername.$dirty ? $v.cedantUserUsername.$error : null  }">
      <label class="gavc-label" for="registration-step-1-cedantUserUsername-group">
        Email :
        <span class="gavc-required"> * </span>
        <p class="gavc-legende"> Il s'agira de votre identifiant pour vous connecter</p>
      </label>
      <input
        class="gavc-input"
        id="registration-step-1-cedantUserUsername-group"
        maxlength="150"
        @input="$v.cedantUserUsername.$touch()"
        type="email"
        @change="findUserByUsername"
        placeholder="jon@donym.fr"
        v-model="cedantUserUsername"
      >
      <p v-if="isExistingUser" class="mt-1 gavc-legende gavc-text-rose">
        Un utilisateur avec cet email existe déjà, veuillez choisir un autre email.
      </p>
      <p v-if="cedantUserUsername && isExistingUser === false" class="mt-1 gavc-legende text-success">
        Cet identifiant est disponible
      </p>
    </div>

    <div
      class="gavc-field"
      v-bind:class="{ 'is-error': cedantUserPassword && $v.cedantUserPassword.$dirty ? $v.cedantUserPassword.$error : null  }">
      <label class="gavc-label" for="registration-step-1-cedantUserPassword-group">
        Mot de passe :
        <span class="gavc-required"> * </span>
      </label>
      <passwordInput
        id="registration-step-1-cedantUserPassword-group"
        maxLength="100"
        @value-changed="cedantUserPassword = $event"
        @input="$v.cedantUserPassword.$touch()"
        :defaultValue="cedantUserPassword"
      />
      <p
          class="gavc-legende mt-2"
          v-if="!$v.cedantUserPassword.password_validation"
        >
          Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.
        </p>
    </div>

    <div
      class="gavc-field"
      v-bind:class="{ 'is-error': $v.cedantUserPasswordConfirmation.$dirty ? $v.cedantUserPasswordConfirmation.$error : null  }">
      <label class="gavc-label" for="registration-step-1-cedantUserPasswordConfirmation-group">
        Confirmez votre mot de passe :
        <span class="gavc-required"> * </span>
      </label>
      <passwordInput
        id="registration-step-1-cedantUserPasswordConfirmation-group"
        maxLength="100"
        @value-changed="cedantUserPasswordConfirmation = $event"
        @input="$v.cedantUserPasswordConfirmation.$touch()"
        :defaultValue="cedantUserPasswordConfirmation"
      />
    </div>

    <div class="gavc-field">
      <div class="gavc-radio-checkbox">
        <div class="gavc-checkbox">
          <input v-model="cedantUserHasAcceptedCGUCGV" type="checkbox" id="registration-step-5-has_accepted_cgu_cgv-group" name="checkbox-test">
          <label class="gavc-label" for="registration-step-5-has_accepted_cgu_cgv-group">
            En cochant cette case, je certifie avoir lu et accepté les
            <a
              target="_blank"
              href="https://www.garance-a-vos-cotes.fr/mentions-legales-conditions-utilisations/"
              data-description=""
            >
              Conditions d'utilisation
            </a>
            et la <a
              target="_blank"
              href="https://www.garance-a-vos-cotes.fr/politique-de-confidentialite/"
              data-description=""
            >
              Politique de confidentialité
            </a>.
            <i class="gavc-icon gavc-icon-check" ></i>
          </label>
        </div>
      </div>
    </div>

    <div class="gavc-field">
      <div class="gavc-radio-checkbox">
        <div class="gavc-checkbox">
          <input v-model="cedantHasAcceptedRelaiAnnonce" type="checkbox" id="registration-step-5-cedantHasAcceptedRelaiAnnonce-group" name="checkbox-test">
          <label class="gavc-label" for="registration-step-5-cedantHasAcceptedRelaiAnnonce-group">
            J’autorise Garance à vos côtés à relayer mon annonce sur tous ses sites partenaires
            <i class="gavc-icon gavc-icon-check" ></i>
          </label>
        </div>
      </div>
    </div>

    <div class="gavc-field">
      <div class="gavc-radio-checkbox">
        <div class="gavc-checkbox">
          <input v-model="cedantHasAuthorizedContact" type="checkbox" id="registration-step-5-cedantHasAuthorizedContact-group" name="checkbox-test">
          <label class="gavc-label" for="registration-step-5-cedantHasAuthorizedContact-group">
            J’autorise Garance à vos côtés à me contacter pour me conseiller dans mon projet de cession ou me porter assistance dans mon projet
            <i class="gavc-icon gavc-icon-check" ></i>
          </label>
        </div>
      </div>
    </div>

    <div class='row mb-4'>
      <div class="col-12 text-right">
        <button
          class="mt-2 gavc-btn gavc-btn--bone"
          @click="previousStep">
            Précédent
        </button>

        <button
          class="mt-2 ml-2 gavc-btn"
          @click="registerCedant"
          :disabled="isInvalidInformation">
            Créer mon compte
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import { createHelpers } from 'vuex-map-fields';

import { APIDisconnectedService } from '@/api/APIDisconnected'
import uxMixins from '@/mixins/uxMixins'
import { password_validation } from '@/validators/validators'

const passwordInput = () => import('@/components/inputs/passwordInput')

const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});

const apiDisconnectedService = new APIDisconnectedService()


export default {
  name: 'cedantRegistrationValidation',
  components: {
    passwordInput
  },
  mixins: [
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isExistingUser: null,
    }
  },
  computed: {
    ...mapFields([
      "cedant.cedantHasAcceptedRelaiAnnonce",
      "cedant.cedantHasAuthorizedContact",
      'cedant.user.cedantUserHasAcceptedCGUCGV',
      'cedant.user.cedantUserPassword',
      'cedant.user.cedantUserPasswordConfirmation',
      'cedant.user.cedantUserUsername',
    ]),
    cedantRegistrationForm () {
      return this.$store.getters.getCedantRegistrationForm
    },
    isInvalidInformation () {
      if (this.$v.$invalid || !this.cedantUserHasAcceptedCGUCGV) {
        return true
      }
      return false
    }
  },

  validations: {
    cedantUserHasAcceptedCGUCGV: {
      required
    },
    cedantUserPassword: {
      maxLength: maxLength(150),
      required,
      password_validation
    },
    cedantUserPasswordConfirmation: {
      maxLength: maxLength(150),
      required,
      sameAsPassword: sameAs('cedantUserPassword'),
    },
    cedantUserUsername: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required,
      email
    }
  },
  mounted () {
    this.scrollToTop()
    if (this.cedantUserUsername) {
      this.findUserByUsername()
    }
  },
  methods: {
    findUserByUsername () {
      if (this.$v.cedantUserUsername.$invalid) {
        return
      }
      apiDisconnectedService.findUserByUsername(this.cedantUserUsername)
      .then((result) => {
        this.isExistingUser = result.data.isUser
      })
      .catch((error) => {
        throw new Error('cedantRegistrationStep4 findUserByUsername API Error : ' + String(error))
      })
    },
    previousStep () {
      this.$emit('previousStep')
    },
    registerCedant() {
      this.$store.commit('loadFromAPI')
      apiDisconnectedService.registerCedant(this.cedantRegistrationForm)
      .then(() => {
        const username = this.cedantRegistrationForm.user.username.toLowerCase().trim()
        const password = this.cedantRegistrationForm.user.password
        this.$store.dispatch('AUTH_REQUEST', { username, password })
        .then(() => {
          this.$router.push('/inscription/cedant/validation')
        })
        .catch(() => {
          this.$router.push('/inscription/validation')
        })
      })
      .catch((error) => {
        if (error.response && error.response.data.user.username && error.response.data.user.username[0] === "Un utilisateur avec ce nom existe déjà.") {
          this.isExistingUser = true
        }
        else {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('Register register API Error : ' + String(error))
        }
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    }
  }
}
</script>
